.description {
    font-size: 1.4rem;
    margin: 1rem 15px;
    color: var(--font-color-3);
}

.external-link {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 1.5rem;
    display: inline-block;
    margin: 10px;
    margin-top: 30px;
    padding: 5px 10px;
    border: 3px solid var(--secondary-color);
    border-radius: .3rem;
}

.external-link:hover {
    opacity: 60%;
}

.waves {
    width: 100%;
    content: url("https://api.alexstaubin.com/public/images/layered-waves-large.svg");
    margin-bottom: -4px;
}

@media (max-width: 650px) {
    .waves { content: url("https://api.alexstaubin.com/public/images/layered-waves-small.svg"); }
}
