.page {
  display: flex;
  flex-direction: column;
  color: var(--font-color-3);
}

.header {
  margin: 15px 10px 0px 10px;
  color: var(--font-color-2);
}

.description {
  font-size: 1.4rem;
  margin: 15px;
}

.buttons { margin-bottom: 35px; }

.button {
  cursor: pointer;
  color: var(--primary-color);
  border: 3px solid var(--primary-color);
  border-radius: .3rem;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 0px;
  margin-top: 10px;
  margin-left: 10px;
  width: 120px;
}

.button:hover {
  opacity: 60%;
}

.button-break { display: none; }

.page-separator {
  margin: 0 12px;
  border: 1px solid lightgray;
}

.resume {  margin: 10px 20px; }

.item-description {
  margin: 0;
}

.bullet-list {
  margin: 0;
}

.bullet-list .item {
  margin-bottom: 0;
}

.item-date { margin: 0; }
.award { margin-bottom: 0; }

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.web-link {
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
}

.web-link:hover,
.web-link:focus {
  text-decoration: underline;
}

.printed-links {
  display: none;
}

.resume-header {
  margin: 20px 0px 5px 0px;
  font-size: 1.6rem;
  color: var(--font-color-2);
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-left: 5px;
}

.waves {
  width: 100%;
  content: url("https://api.alexstaubin.com/public/images/layered-waves-large.svg");
  margin-bottom: -4px;
}

@media (max-width: 650px) {
  .waves { content: url("https://api.alexstaubin.com/public/images/layered-waves-small.svg"); }
}

@media (max-width: 1000px) {
  .item { flex-direction: column-reverse; }
  .button-break { display: block }
}

@media print {
  .limited-width {  margin-top: 0; }
  nav { display: none; }
  .page-top { display: none; }
  .web-link { display: none; }
  .waves { display: none; }
  footer { display: none; }
  .printed-links { display: block; }
  .resume-header { font-size: 1rem; }
  .item { flex-direction: row; }
  .page { color: var(--print-color-2); }
  .header { color: var(--print-color-1); }
  .resume-header { color: var(--print-color-1); }

  .resume {
    font-size: .7rem;
    margin: 0;
  }
}
