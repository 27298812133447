.page {
  display: flex;
  flex-direction: column;
}

.page-header {
  margin: 15px 10px 0px 10px;
  color: var(--font-color-2);
}

.description {
  font-size: 1.4rem;
  margin: 15px;
  color: var(--font-color-3);
}

.search {
  flex: 1;
  margin: 15px;
  margin-right: 19px;
  font-size: 1.4rem;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 48%;
  aspect-ratio: 16/9;
  background-size: cover ;
  background-position: center;
  margin: 4px;
  border: 2px black solid;
  border-radius: .5rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: var(--header-font-color);
  background-color: var(--tertiary--bg-color);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.card:hover {
  opacity: 40%;
}

.card .name {
  margin: 0;
}

.waves {
  width: 100%;
  content: url("https://api.alexstaubin.com/public/images/layered-waves-large.svg");
  margin-bottom: -4px;
}

@media (max-width: 650px) {
  .waves { content: url("https://api.alexstaubin.com/public/images/layered-waves-small.svg"); }
}

@media (max-width: 900px) {
  .card {
    font-size: 1rem;
  }
}

@media (max-width: 700px) {
  .card {
    width: 96%;
    font-size: 1.4rem;
  }
}