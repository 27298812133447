.navbar {
  text-decoration: none;
  background-color: var(--secondary-bg-color);
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 53px;
  font-weight: bold;
}
.logo {
  height: 35px;
}

.navbar .limited-width {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar a {
  color: var(--primary-color);
}

.navbar a:hover {
  text-decoration: underline;
  opacity: 70%;
}

.navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.home-button {
  height: 100%;
  margin: 0px 10px;
  display: flex;
  align-items: center;
}
.home-button a {
  text-decoration: none;
}

/* page selector */
.navbar-links {
  display: flex;
}

.navbar-links a {
  padding: 0 20px;
  font-size: 1.1rem;
  text-decoration: none;
}

.navbar .navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar .navbar-links li {
  list-style: none;
}

.navbar .navbar-links .active {
  color:dimgrey;
  font-weight: bold;
}

/* hamburger menu */
.hamburger-menu {
  display: none;
  position: absolute;
  z-index: 11;
  top: .7rem;
  right: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.hamburger-menu .bar {
  width: 30px;
  margin: 2.5px 0px;
  height: 100%;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 10px;
}

/* activate hamburger menu when page too small */
@media (max-width: 1000px) {
  .hamburger-menu {
    display: flex;
  }

  .navbar-links {
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--secondary-bg-color);
    z-index: 9;
    margin-top: 53px;
  }
  
  .navbar-items {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar a {
    font-size: 1.4rem;
  }

  .navbar-links a {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar-links ul {
    width: 100%;
    flex-direction: column;
    z-index: 1;
    box-shadow: 0 2px 0 gray;
  }

  .navbar .navbar-links li {
    box-shadow: 0 -2px 0 gray;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
  }

  .navbar-links.active {
    display: flex;
  }
}
