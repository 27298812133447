.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
}

.small-br {
    display: none;
}

.home-header {
    font-size: 7.7rem;
    margin: 1.3em 0px;
    text-align: center;
    color: var(--font-color-2);
}

.bottom-header {
    font-size: 3.5rem;
}

.cta-button {
    display: inline-block;
    text-align: center;
    margin: 10px 20px;
    padding: 7px 15px;
    width: 350px;
    text-decoration: none;
    border-radius: .3em;
    font-weight: bold;
    font-size: 2rem;
    border: 2px solid var(--secondary-color);
    color: var(--secondary-color);
    box-shadow: 0 0 10px 1px var(--secondary-color);
}

.waves {
    width: 100%;
}

.purple {
    margin-top: -4px;
    padding: 20px;
    padding-bottom: 200px;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
}

.purple .limited-width {
    margin: 0 auto;
}

.project-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center;
    aspect-ratio: 16/9;
}

.project-header {
    font-size: 3rem;
    padding: 0px 40px;
    color: var(--header-font-color);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@media (max-height: 850px) {
    .home-header {
        font-size: 6rem;
        margin: .8em 0px;
    }

    .cta-button {
        margin: 10px 20px;
        padding: 10px 15px;
        width: 300px;
        font-size: 1.5rem;
    }
}

@media (max-width: 900px) {
    .project-header { font-size: 3rem;  }
    .home-header { margin: .6em 0px; }
    .small-br { display: block; }
}

@media (max-width: 800px) {
    .project-header { font-size: 2rem;  }
}

@media (max-width: 750px) {
    .home-header {
        font-size: 6rem;
        margin: .6em 0px;
    }
        
    .bottom-header {
        font-size: 3rem;
    }

    .cta-button {
        margin: 10px 20px;
        padding: 10px 15px;
        width: 300px;
        font-size: 1.5rem;
    }

    .small-br { display: block; }
}

.waves {
    width: 100%;
    content: url("https://api.alexstaubin.com/public/images/layered-waves-large.svg");
  }
  
@media (max-width: 650px) {
    .waves { content: url("https://api.alexstaubin.com/public/images/layered-waves-small.svg"); }
    .top {  margin: 80px auto; }
}
