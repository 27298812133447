:root {
  --primary-color: #8f00ff;
  --secondary-color: #c29e08;
  --tertiary-color: #ff5f1f;
  --lilac-color: #c8a2c8;
  /* --tertiary-color: #58CD36; */
  --bg-color: #121212;
  --secondary-bg-color: #0a0a0a;
  --tertiary--bg-color: #1f1f1f;
  --header-font-color: #e1e1e1;
  --font-color-1: #f7f7f7;
  --font-color-2: #e1e1e1;
  --font-color-3: #cfcfcf;
  --font-color-4: #b1b1b1;
  --font-color-5: #9e9e9e;
  --print-color-1: #121212;
  --print-color-2: #212121;
}

[data-theme='light'] {
  --bg-color: #eeeeee;
  --secondary-bg-color: #bdbdbd;
  --tertiary--bg-color: #e0e0e0;
  --font-color-1: #121212;
  --font-color-2: #212121;
  --font-color-3: #424242;
  --font-color-4: #616161;
  --font-color-5: #757575;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
