.contact-page {
    display: flex;
    flex-direction: column;
    color: var(--font-color-3);
}

.header {
    margin: 15x 10px 0px 10px;
    color: var(--font-color-2);
}

.description {
    font-size: 1.4rem;
    margin: 10px 15px 0px 15px;
}

.form {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
}

.form input,
.form select,
.form textarea {
    font-size: 1.4rem;
    margin: 0px 15px;
}

.form label {
    font-weight: bold;
    margin: 15px 10px 5px 10px;
}

.form .submit-btn {
    margin-top: 15px;
}

.green {
    color: green;
}

.red {
    color: red;
}

.waves {
    width: 100%;
    content: url("https://api.alexstaubin.com/public/images/layered-waves-large.svg");
    margin-bottom: -4px;
}

@media (max-width: 650px) {
    .waves { content: url("https://api.alexstaubin.com/public/images/layered-waves-small.svg"); }
}
