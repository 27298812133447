.header {
  margin: 0px 10px;
  color: var(--font-color-2);
}

.tags {
  padding: 0px 10px;
}
    
.tags .tag {
  display: inline-block;
  border: 3px solid var(--tertiary-color);
  border-radius: 2rem;
  padding: 5px 10px;
  margin: 5px;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--tertiary-color);
}

.tags .tag:hover {
  opacity: 60%;
}