.App {
  background-color: var(--bg-color);
}

.limited-width {
  max-width: 1100px;
  margin: auto;
  margin-top: 53px;
  padding: 0;
}
