.header {
    position: relative;
    height:500px;
    background-color: var(--tertiary--bg-color);
    display: flex;
    justify-content: center;
}

.thumbnail {
    height: 500px;
    width: 100%;
    background-size: cover ;
    background-position: center;
    top: 0;
    left: 0;
    position: absolute;
}

.title {
    z-index: 1;
    color: var(--header-font-color);
    text-align: center;
    align-self: flex-end;
    font-size: 3.5rem;
    padding: 0px 50px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@media (max-width: 700px) {
    .header { height:400px; }
    .header .thumbnail { height: 400px; }
    .header .title { font-size: 2.8rem; }
}

@media (max-width: 675px) {
    .header .title { font-size: 2.5rem; }
}