.legal {
    color: var(--font-color-3);
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.legal h1,
.legal h2 {
    color: var(--font-color-2)
}