footer {
    background-color: var(--secondary-bg-color);
}

footer .limited-width {
    margin-top: 0;
    padding: 10px;
    color: var(--font-color-3);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-btns {
    display: flex;
    align-items: center;
}

.social-btn {
    cursor: pointer;
    width: 35px;
    margin-right: 25px;
}

.theme-btn {
    cursor: pointer;
    display: flex;
    background: none;
    border: none;
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--font-color-2);
}

.selected-theme {
    color: var(--primary-color)
}

.legal {
    display: flex;
    color: var(--primary-color)
}

.legal-link {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
}

.legal-link:hover,
.legal-link:focus {
    text-decoration: underline;
}

.separator {
    margin: 0px 6px;
}

@media (max-width: 800px) {
    footer .limited-width {
        flex-direction: column;
    }
}